.user-view-btn {
  display: flex;
  justify-content: center;
  padding: 4px;
}

.slider .edit-add .edit-add-field .MuiAutocomplete-clearIndicator {
  display: none;
}

/* ========== Custom Styles delete Modal =========== */

.delete-modal .delete-modal-title p {
  color: #fff !important;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
}
.delete-modal .delete-modal-line hr {
  border-top: 1px solid rgb(255, 255, 255, 1) !important;
}
.delete-modal .modal-body {
  padding: 20px 0px 60px !important;
}

.delete-modal .modal-body .modal-body-title {
  color: #fff !important;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  padding: 20px 0px;
}

.delete-modal .modal-body .modal-body-btn {
  display: flex;
  justify-content: space-around;
}

.delete-modal .modal-body .modal-body-btn .delete-btn {
  background-color: #e74c3c;
  min-width: 25% !important;
  color: #fff !important;
}

.delete-modal .modal-body .modal-body-btn .delete-btn:hover {
  box-shadow: 0px 5px 20px rgba(231, 76, 60, 0.5);
  transform: translateY(-3px);
}

.delete-modal .modal-body .modal-body-btn .view-btn {
  background-color: #1c6da8;
  min-width: 25% !important;
  color: #fff !important;
}

.delete-modal .modal-body .modal-body-btn .view-btn:hover {
  box-shadow: 0px 5px 20px rgba(28, 109, 168, 0.5);
  transform: translateY(-3px);
}