
hr {
  width: 100%;
}

p {
  color: #000000 !important;
}

.Toastify__toast--success {
  background: #14A8A6;
}

/* ========== Custom Scroll Styles =========== */

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #27293d;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #333645;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #333645;
}


/* ========== Custom MainContainer Styles =========== */

.main-container {
    background-color: #ffffff !important;
    padding: 20px 30px !important;
    border-radius: 4px !important;
    box-shadow: 0 3px 8px -3px !important;
    min-height: 90vh;
}

.main-container-head {
  display: flex;
  justify-content: space-between;
}

.main-container-heading {
  color: #000000;
  font-size: 22px;
  font-weight: 550;
}

/* ========== Custom Table Styles =========== */

.table .rt-tbody .rt-td {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ========== Custom Slider Styles =========== */

.slider {
  padding: 30px 90px;
  width: 800px;
}

/*========== Custom Styles View Data  ============= */

.view-data .view-data-main-title {
  font-weight: 500;
  font-size: 26px;
  padding-bottom: 5px;
}

.view-data .view-data-body .view-data-row {
  background-color: #eaeded  ;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 16px;
}

.view-data .view-data-body .view-data-row .view-data-title {
  font-weight: 600;
}

.view-data .view-data-body .view-data-sub-title {
  font-size: 20px;
  font-weight: 600;
}

.view-data .view-data-footer {
  display: flex;
  justify-content: center;
}


/*========== Custom Styles Edit-Add Slider ============= */

.edit-add {
  text-align: center;
  padding: 30px 70px;
  border-radius: 20px;
  transition: all .5s ease-in-out;
  -webkit-transition: all .5s ease-in-out;
  box-shadow: 0 4px 29px rgba(47,47,47,.1);
}

.edit-add .edit-add-title {
padding: 15px;
font-size: 26px;
font-weight: 500;
text-align: center;
}

.edit-add .edit-add-sub-title {
  font-size: 20px;
  font-weight: 500;
  text-align: start;
  }

.edit-add .edit-add-body .edit-img {
  width: 120px; 
  height: 120px; 
  border-radius: 50%;
  background: 'gray';
}

.edit-add .edit-add-body .edit-add-field .form-input-field
.MuiFormLabel-root.Mui-focused {
  color: #333645;
}

.edit-add .edit-add-body .form.edit-add-field .form-input-field
.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: 1px solid #6c757d;
}

.edit-add .edit-add-body .edit-add-field .form-input-field
.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: 2px solid #333645;
}

.edit-add .edit-add-body .edit-add-field .form-input-field
.MuiOutlinedInput-notchedOutline {
  border: 1px solid #6c757d;
}


.MuiAutocomplete-option {
  color: #000000;
}

.edit-add .edit-add-body .edit-add-buttons {
display: flex;
padding: 30px 90px;
justify-content: space-between;
}

/* ========== Custom Button Styles =========== */


.add-btn {
  color: #333645 !important;
  padding: 5px 15px !important;
  border-radius: 10px !important;
  border: 1px solid #333645 !important;
}

.add-btn:hover {
  color: #ffffff !important;
  background-color: #1c6da8 !important;
}

.view-btn {
  min-width: 45% !important;
  color: #333645 !important;
  border-radius: 10px !important;
  border: 1px solid #333645 !important;
}

.view-btn:hover {
  color: #ffffff !important;
  background-color: #1c6da8 !important;
}

.edit-btn {
  min-width: 45% !important;
  color: #333645 !important;
  border-radius: 10px !important;
  border: 1px solid #333645 !important;
}

.edit-btn:hover {
  color: #ffffff !important;
  background-color: #1c6da8 !important;
}

.delete-btn {
  min-width: 45% !important;
  color: #e74c3c !important;
  border-radius: 10px !important;
  border: 1px solid #e74c3c !important;
}

.delete-btn:hover {
  color: #ffffff !important;
  background-color: #e74c3c !important;
}

.cancel-btn {
  border-radius: 10px !important;
  color: #ffffff !important;
  background: #e74c3c  !important;
}

.submit-btn {
    border-radius: 10px !important;
    color: #ffffff !important;
    background: #1c6da8  !important;
}

.confirm-btn {
  border-radius: 10px !important;
  color: #ffffff !important;
  background: #333645  !important;
}

Button:focus {
    outline: 0px !important; 
}

/* ========== Custom Loader Styles =========== */

.loader-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
  color: #1c6da8;
}

.blue-loader {
  color: #1c6da8;
}

/* ========== Main Modal Styles =========== */

.main-modal {
  max-width: 500px !important;
  top: 5%;
}

.main-modal .modal-header,
.modal-footer {
  border: none !important;
}

.main-modal .modal-content {
  background-color: #333645 !important;
  border-radius: 30px !important;
}

.main-modal .modal-content .modal-header {
  display: flex;                   
  align-items: center;
  justify-content: center;
  padding: 0px;
}

.main-modal .modal-content .modal-header .close {
  color: #fff !important;
}

.main-modal .modal-content .modal-header .modal-logo {
  display: flex;
  padding: 25px 0px 35px;
  justify-content: center;
}

.main-modal .modal-content .modal-header .modal-logo img {
  width: 40%;
}

.main-modal .modal-content .modal-body {
  padding: 0px;
}



/*========== Responsive ============= */

@media only screen and (max-width: 650px){

  .slider {
      padding: 30px;
      width: 500px;
  }

  .slider .edit-add {
      padding: 20px 10px;
  }

  .slider .edit-add .slider-body .edit-add-buttons {
      padding: 30px;
  }
}

@media only screen and (max-width: 425px){
  .slider {
      padding: 20px;
      width: 400px;
  }
}

@media only screen and (max-width: 375px){
  .slider {
      padding: 10px;
      width: 325px;
  }
}
